//
// Mixins: Accent
//

// Accent Variant
@mixin accent-variant($name, $color) {
  .accent-#{$name} {
    $link-color: $color;
    $link-hover-color: darken($color, 15%);
    $pagination-active-bg: $color;
    $pagination-active-border-color: $color;

    a {
      color: $link-color;

      @include hover {
        color: $link-hover-color;
      }
    }

    [class*="sidebar-light-"],
    &[class*="sidebar-dark-"] {
        .nav-sidebar .nav-treeview > .nav-item > {
        .nav-link:not(.active):hover {
          color: $color;
        }
      }
    }
    
    .page-item {
      &.active .page-link {
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
      }

      &.disabled .page-link {
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
      }
    }
  }
}

